import { parsePhoneNumberFromString } from 'libphonenumber-js';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

jQuery(function ($) {

    tippy('[data-tippy-content]', {
        allowHTML: true,
        interactive: true,
        placement: 'top',
        maxWidth: 300,
        delay: [0, 0],
        arrow: true,
    });

    $.validator.setDefaults({
        ignore: [],
        errorElement: 'span',
        errorClass: 'invalid',
        errorPlacement: function (error, element) {
            var placement = $(element).data('error');
            if (placement) {
                $(placement).append(error)
            } else {
                error.insertAfter(element);
            }
        },
        validClass: "valid",
    });

    $('#application-form-credit-limit').validate({
        rules: {
            amount: "required",
            alt_offer_m: "required",
        },
        messages: {
            amount: "Ange önskad kreditgräns",
            alt_offer_m: "Vänligen välj layout",


        },
        submitHandler: function (form, e) {
            sendCreateApplicationCreditLimit(form, e);
        }
    });

    $('#application-form-step-one').validate({
        rules: {
            amount: "required",
            email: {
                required: true,
                email: true,
            },
            phone_number: {
                required: true,
                validPhoneNumber: "SE",
            },
            civil_status: "required",
            form_of_employment: "required",
            monthly_income: {
                required: true,
                number: true,
            },
            alt_offer_m: "required",
        },
        messages: {
            amount: "Ange önskad kreditgräns",
            email: {
                required: "Ange din e-mail",
                email: "Ange en giltig e-mailadress",
            },
            phone_number: {
                required: "Ange ditt telefonnummer",
                validPhoneNumber: "Ange ett giltigt telefonnummer"
            },
            civil_status: "Ange din civilstatus",
            form_of_employment: "Ange din anställningsform",
            monthly_income: {
                required: "Ange månadsinkomst före skatt",
                number: "Ange endast siffror"
            },
            alt_offer_m: "Vänligen välj layout",


        },
        submitHandler: function (form, e) {
            sendCreateApplicationStepOne(form, e);
        }
    });

    $('#application-form-step-two').validate({
        rules: {
            alt_offer_e: {
                required: {
                    depends: function () {
                        return ($('input[name="extra_card"]:checked').data('extra-card') == "yes");
                    }
                }
            },
            co_pnr: {
                isValidSwedishSSN: true,
                number: true,
                required: {
                    depends: function () {
                        return ($('input[name="extra_card"]:checked').data('extra-card') == "yes");
                    }
                }
            },
            monthly_mortgage_expenses: {
                required: {
                    depends: function () {
                        return ($('input[name="have_mortgage"]:checked').val() == "true");
                    }
                },
            },
            monthly_loan_expenses: {
                required: {
                    depends: function () {
                        return ($('input[name="have_other_loans"]:checked').val() == "true");
                    },
                },
            },
            capital_debt_loans: {
                required: {
                    depends: function () {
                        return ($('input[name="have_other_loans"]:checked').val() == "true");
                    },
                },
            },
            personal_guarantee_amount: {
                required: {
                    depends: function () {
                        return ($('input[name="have_personal_guarantee"]:checked').val() == "true");
                    },
                },
            },
            /* extra_card: {
                required: true,
            }, */
            adults_in_household: {
                required: true,
                number: true,
                min: 0,
            },
            children_in_household: {
                required: true,
                number: true,
                min: 0,
            },
            form_of_housing: "required",
            rent_amount: "required",
            number_of_cars: {
                required: true,
                number: true,
                min: 0,
                max: 15,
            },
            payment_remark: "required",
            have_mortgage: "required",
            have_other_loans: "required",
            have_personal_guarantee: "required",
        },
        messages: {
            alt_offer_e: {
                required: "Vänligen välj layout",
            },
            co_pnr: {
                required: "Ange extrakortsökandens personnummer 12 siffror",
                isValidSwedishSSN: "Kontrollera personnumret",
                number: "Ange endast siffror",
            },
            extra_card: {
                required: "Vänligen välj om du vill beställa extrakort eller ej",
            },
            personal_guarantee_amount: "Ange belopp för borgensåtagande",
            adults_in_household: {
                required: "Ange antal vuxna i hushållet",
                number: "Ange endast siffror",
                min: "Ange minst 0 vuxna",
            },
            children_in_household: {
                required: "Ange antal barn under 18 år i hushållet",
                number: "Ange endast siffror",
                min: "Ange minst 0 barn",
            },
            form_of_housing: "Ange typ av boende",
            monthly_loan_expenses: "Ange månadskostnad för lån",
            monthly_mortgage_expenses: "Ange månadskostnad för bolån",
            capital_debt_loans: "Ange kapitalskuld för lån",
            rent_amount: "Ange hyreskostnad",
            number_of_cars: {
                required: "Ange antalet bilar",
                number: "Ange endast siffror",
                min: "Ange minst 0 bilar",
                max: "Ange max 15 bilar",
            },
            payment_remark: "Ange om du har betalningsanmärkningar",
            have_mortgage: "Ange om du har bolån",
            have_other_loans: "Ange om du har andra lån",
            have_personal_guarantee: "Ange om du har borgensåtagande",
        },
        submitHandler: function (form, event) {
            sendCreateApplicationStepTwo(form, event);
        }
    });

    $('#application-form-summary').validate({
        rules: {
            villkor: "required",
        },
        messages: {
            villkor: "Vänligen acceptera villkoren",
        },
        submitHandler: function (form, event) {
            sendApplication(form, event);
        }
    });

    $.validator.addMethod(
        "validPhoneNumber",
        function (value, element, defaultCountry) {
            // If the field is empty, let the 'required' rule handle it.
            if (this.optional(element)) {
                return true;
            }

            // Parse the phone number using the provided default country (e.g., "SE").
            const phoneNumber = parsePhoneNumberFromString(value, defaultCountry);

            // Check if parsing was successful and if the phone number is valid.
            if (phoneNumber && phoneNumber.isValid()) {
                $(element).val(phoneNumber.formatInternational().replace(/\s+/g, ''));
                return true;
            }

            return false;
        },
        "Ange ett giltigt telefonnummer" // Default error message
    );

    $.validator.addMethod("notEqualTo", function (value, element, param) {
        return this.optional(element) || value != $(param).val();
    }, "Felaktigt värde");

    $.validator.addMethod('isValidSwedishSSN', function (value, element) {

        if (this.optional(element)) {
            return true;
        }

        let ssn = value
            .replace(/\D/g, "")     // strip out all but digits
            .split("")              // convert string to array
            .reverse()              // reverse order for Luhn

        // verify we got 12 digits, otherwise it is invalid
        if (ssn.length != 12) {
            return false;
        }

        let prefix = value
            .replace(/\D/g, "")
            .substring(0, 2);

        let fullYear = value
            .replace(/\D/g, "")
            .substring(0, 4);

        let fullDate = value
            .replace(/\D/g, "")
            .substring(0, 8);

        // ssn must include a valid date
        let parsedDate = fullDate.substring(0, 4) + '-' + fullDate.substring(4, 6) + '-' + fullDate.substring(6, 8);
        let d = Date.parse(parsedDate);

        if (isNaN(d)) {
            return false;
        }

        // ssn must start with 19 or 20
        if (prefix != 19 && prefix != 20) {
            return false;
        }

        // can't be born in the future
        var currentYear = new Date().getFullYear();
        if (parseInt(fullYear) > parseInt(currentYear)) {
            return false;
        }

        var sum = ssn
            // convert to number
            .map(function (n) {
                return Number(n);
            })
            .slice(0, 10)
            // perform arithmetic and return sum
            .reduce(function (previous, current, index) {
                // multiply every other number with two
                if (index % 2) current *= 2;
                // if larger than 10 get sum of individual digits (also n-9)
                if (current > 9) current -= 9;
                // sum it up
                return previous + current;
            });

        // sum must be divisible by 10
        return 0 === sum % 10;

    });

    /**
     * Handles the submission of the credit limit step of the application form.
     * Prevents the default form submission, shows a loader, serializes the form data,
     * and sends it via an AJAX POST request to save the application data.
     * Redirects to the next step on success or displays an error message on failure.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {Event} e - The event object associated with the form submission.
     */
    function sendCreateApplicationCreditLimit(form, e) {
        e.preventDefault();
        $(document).trigger('sendCreateApplicationCreditLimit');
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var alt_offer = '';
        if (form_data.alt_offer_m == 1) {
            alt_offer = 12;
        } else if (form_data.alt_offer_m == 2) {
            alt_offer = 13;
        } else if (form_data.alt_offer_m == 3) {
            alt_offer = 16;
        }

        var data = {
            action: 'save_application_data',
            security: CGIApplicationAjax.security,
            data: {
                credit_limit_amount: form_data.credit_limit_amount,
                campaign_code: form_data.campaign_code,
            }
        }

        if (alt_offer != '') {
            data.data.alt_offer = alt_offer;
        }

        $.post(CGIApplicationAjax.ajaxurl, data, function (response) {
            if (response.success) {
                window.location.assign(CGIApplicationAjax.applicationAboutCustomer);
            } else {
                try {
                    $('#application-form-step-one .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('#application-form-step-one .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    }

    /**
     * Handles the submission of the first step of the application form.
     * Prevents the default form submission, shows a loader, serializes the form data,
     * and sends it via an AJAX POST request to save the application data.
     * Redirects to the next step on success or displays an error message on failure.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {Event} e - The event object associated with the form submission.
     */
    function sendCreateApplicationStepOne(form, e) {
        e.preventDefault();
        $(document).trigger('sendCreateApplicationStepOne');
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var data = {
            action: 'save_application_data',
            security: CGIApplicationAjax.security,
            data: {
                monthly_income: form_data.monthly_income,
                form_of_employment: form_data.form_of_employment,
                civil_status: form_data.civil_status,
                email: form_data.email,
                phone_number: form_data.phone_number,
            }
        }

        $.post(CGIApplicationAjax.ajaxurl, data, function (response) {
            if (response.success) {
                window.location.assign(CGIApplicationAjax.applicationEconomy);
            } else {
                try {
                    $('#application-form-step-one .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('#application-form-step-one .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    }

    /**
     * Handles the submission of the second step of the application form.
     * Prevents the default form submission, shows a loader, serializes the form data,
     * and sends it via an AJAX POST request to save the application data.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {Event} event - The event object associated with the form submission.
     */
    function sendCreateApplicationStepTwo(form, event) {
        event.preventDefault();
        $(document).trigger('sendCreateApplicationStepTwo');
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var alt_offer = '';
        if (form_data.alt_offer_e != '' && form_data.extra_card != '') {
            if (form_data.alt_offer_m == 1 && form_data.alt_offer_e == 1) { alt_offer = 12; }
            if (form_data.alt_offer_m == 1 && form_data.alt_offer_e == 2) { alt_offer = 14; }
            if (form_data.alt_offer_m == 1 && form_data.alt_offer_e == 3) { alt_offer = 19; }
            if (form_data.alt_offer_m == 2 && form_data.alt_offer_e == 2) { alt_offer = 13; }
            if (form_data.alt_offer_m == 2 && form_data.alt_offer_e == 1) { alt_offer = 15; }
            if (form_data.alt_offer_m == 2 && form_data.alt_offer_e == 3) { alt_offer = 20; }
            if (form_data.alt_offer_m == 3 && form_data.alt_offer_e == 3) { alt_offer = 16; }
            if (form_data.alt_offer_m == 3 && form_data.alt_offer_e == 1) { alt_offer = 17; }
            if (form_data.alt_offer_m == 3 && form_data.alt_offer_e == 2) { alt_offer = 18; }
        }

        var data = {
            action: 'save_application_data',
            security: CGIApplicationAjax.security,
            data: {
                form_of_housing: form_data.form_of_housing,
                have_mortgage: form_data.have_mortgage == 'true' ? 1 : 0,
                have_other_loans: form_data.have_other_loans == 'true' ? 1 : 0,
                number_of_cars: form_data.number_of_cars,
                payment_remark: form_data.payment_remark == 'true' ? 1 : 0,
                adults_in_household: form_data.adults_in_household,
                children_in_household: form_data.children_in_household,
                rent_amount: form_data.rent_amount,
                have_personal_guarantee: form_data.have_personal_guarantee == 'true' ? 1 : 0,
            }
        }

        if (form_data.have_mortgage == 'true') {
            data.data.monthly_mortgage_expenses = form_data.monthly_mortgage_expenses;
        } else {
            data.data.monthly_mortgage_expenses = 'null';
        }

        if (form_data.have_other_loans == 'true') {
            data.data.monthly_loan_expenses = form_data.monthly_loan_expenses;
            data.data.capital_debt_loans = form_data.capital_debt_loans;
        } else {
            data.data.monthly_loan_expenses = 'null';
            data.data.capital_debt_loans = 'null';
        }

        if (form_data.have_personal_guarantee == 'true') {
            data.data.personal_guarantee_amount = form_data.personal_guarantee_amount;
        } else {
            data.data.personal_guarantee_amount = 'null';
        }

        if (alt_offer != '') {
            data.data.alt_offer = alt_offer;
        }

        $.post(CGIApplicationAjax.ajaxurl, data, function (response) {
            if (response.success) {
                window.location.assign(CGIApplicationAjax.applicationSummary);
            } else {
                try {
                    $('#application-form-step-two .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('#application-form-step-two .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    }

    /**
     * Send the application to Customer API.
     *
     * @param {HTMLFormElement} form - The form element that triggered the event.
     * @param {Event} event - The event object associated with the form submission.
     */
    function sendApplication() {
        var data = {
            action: 'new_card_apply',
            security: CGIApplicationAjax.security,
        }
        $(document).trigger('sendCreateApplicationFinal');
        $('.application-loader').removeClass('hidden');

        $.post(CGIApplicationAjax.ajaxurl, data, function (response) {
            if (response.success) {
                if (response.data.preliminary_decision == 'Approved') {
                    window.location.assign(CGIApplicationAjax.applicationKyc);
                } else {
                    window.location.assign(CGIApplicationAjax.rejectedRedirect);
                }
            } else {
                try {
                    $('#application-form-summary .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('#application-form-summary .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    };

    $('input[name="have_mortgage"]').on('change', function () {
        if (this.value == "true") {
            $('#have-mortgage-wrapper').removeClass('hidden');
        } else {
            $('#have-mortgage-wrapper').addClass('hidden');
            $('input[name="monthly_mortgage_expenses"]').val('');
        }
    });

    $('input[name="have_other_loans"]').on('change', function () {
        if (this.value == "true") {
            $('#have-other-loans-wrapper').removeClass('hidden');
        } else {
            $('#have-other-loans-wrapper').addClass('hidden');
            $('input[name="capital_debt_loans"]').val('');
            $('input[name="monthly_loan_expenses"]').val('');
        }
    });

    $('input[name="have_personal_guarantee"]').on('change', function () {
        if (this.value == "true") {
            $('#have-personal-guarantee-wrapper').removeClass('hidden');
        } else {
            $('#have-personal-guarantee-wrapper').addClass('hidden');
            $('input[name="personal_guarantee_amount"]').val('');
        }
    });

    $('input[name="extra_card"]').on('change', function () {
        if (this.value == "true") {
            $('.extra-card-wrapper').removeClass('hidden');
        } else {
            $('.extra-card-wrapper').addClass('hidden');
            $('input[name="co_pnr"]').val('');
            $('input[name="co_firstname"]').val('');
            $('input[name="co_lastname"]').val('');
        }
    });

    $('.logout-application').on('click', function (e) {
        e.preventDefault();
        logoutApplication();
    });

});

/**
 * Logs out the current user by sending an AJAX request to the server.
 * On successful logout, the user is redirected to the login page.
 */
function logoutApplication() {
    var data = {
        action: 'cgi_logout_user',
        security: CGIApplicationAjax.security,
    }

    jQuery.post(CGIApplicationAjax.ajaxurl, data, function () {
        window.location.assign(CGIApplicationAjax.logIn);
    });
}
